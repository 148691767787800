<template>
    <div>change-password</div>
</template>

<script>
  export default {
    layout: 'auth',
    name: 'change-password'
  }
</script>

<style scoped>

</style>
